.content {
  $self: &;

  position: relative;
  width: 100%;

  &__title {
    font-size: 4.8rem;
    line-height: 5rem;
    color: #fff;
  }

  &__block {
    color: #fff;
    padding: 4rem 2rem;
    
    &--grey {
      //background-color: rgba(#DADEDF, 0.2);
    }
  }
  
  &__image {
    height: 100%;
    width: 100%;
  }

  &__image-wrapper {
    height: 100%;
    width: 100%;
    min-height: 12rem;
    position: relative;
    
  }
}