@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.tab {

    &__list {
        font-family: 'Quicksand', sans-serif;
        border-bottom: 0.1rem solid #ccc;
        padding-left: 0;

        &--active {
            background-color: #313345;
            border: solid #ccc;
            border-width: 0.1rem 0.1rem 0 0.1rem;
            box-shadow: 0 0.3rem 0.5rem #313345
        }

    }

    &__list-item {
        font-family: 'Quicksand', sans-serif;
        font-size: 1.2rem ;
        display: inline-block;
        list-style: none;
        margin-bottom: -0.1rem;
        padding: 0.5rem 0.75rem;
        cursor: pointer;
        user-select: none;
    }
}