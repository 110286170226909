@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.nav {
    $self: &;

    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    background-color: transparent;
    position: fixed;
    align-self: center;
    top: 0;
    width: 100%;
    z-index: 5;
    color: #fff;
    transition: background-color  ease-out;

    ul {
        margin: 0;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__icon {
        //display: none;
        position: absolute;
        background: url('../../assets/icons/hamburger-menu-white.svg') no-repeat center center;
        height: 100%;
        width: 100%;
        cursor: pointer;
        
        @media screen and (max-width: 768px) {
            display: inline;
        }
      }

    &__items {
        list-style: none;
        user-select: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item {
        display: flex;
        position: relative;
        align-items: center;
        letter-spacing: 0.05rem;
        padding: 1.5rem 1rem;

        &::before {
            display: none;
        }
    }
    
    &__link {
        display: block;
        position: relative;
        text-decoration: none;
        font-size: 1.2rem;
        color: #fff;
        transition: 300ms ease-in-out;

        &::before {
            content: '';
            position: absolute;
            bottom: -3rem;
            height: 0.05rem;
            width: 100%;
            background: transparent;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 2rem;
            height: 0.2rem;
            transition: width 0.4s ease-in-out, background-color 0.4s ease-in-out;
        }
      
        &:hover {
            text-decoration: none;
            color: #fff;

            &::after {
              background: #fff;
              width: 100%;
            }
        }

        &--active {
            text-decoration: none;
            color: #fff;

            &::after {
              background: #fff;
              width: 100%;
            }
        }

    }

    &--sticky {
        background-color: #313345;
        animation: slideInDown 0.4s cubic-bezier(.11,.28,.58,1);
        box-shadow: 0 -0.5rem 3rem #000;
    }
}





