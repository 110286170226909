@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.header {
  $self: &;

  height: 100vh;
  width: 100%;

  &--small {
    height: 40vh;
    min-height: 20rem;
    margin-bottom: 4rem;
  }

  &__image {
    left: 0;
    top: 0;
    width: 100%;
  }
  
  &__image-wrapper {
    &--large {
      height: 100%;
      background-color: #313345;
      background: url('../../images/code-screen.png') no-repeat center center;
      background-size: cover;
      background-repeat: no-repeat;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #22262d;
        opacity: 0.5;
      }
    }
    
    &--small {
      height: 100%;
      background: url('../../images/laptop-code.jpg') no-repeat center center;
    }
  }

  &__title {
    font-family: 'Quicksand', sans-serif;
    position: absolute;
    text-align: center;
    color: #fff;

    &--large {
      font-size: 6.4rem;
      line-height: 6.6rem;
      margin-top: -4rem;
      bottom: 9rem;
      z-index: 3;
      width: 100%;

      @media screen and (max-width: 768px) {
        font-size: 3rem;
        line-height: 3.2rem;
        margin-top: -4rem;
        bottom: 4rem;
      }
    }
    
    &--small {
      font-size: 4.8rem;
      line-height: 5rem;
      margin-top: -6.5rem;
      top: 25%;
      width: 100%;
      z-index: 1;
    }

  }

  &__title-box {
    position: absolute;
    background-color: #ff0000;
    z-index: 3;
  }

  &__logo-background {
    background-color: #282828;
  }

  &__overlay {
    position: absolute;
    z-index: 4;
  }
}