@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

body {
  font-family: 'Quicksand', sans-serif;
}

ol,
ul,
li {
  color: #fff;
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  font-family: 'Quicksand', sans-serif;
  color: #ffffff;
}


