.single-slide {
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    font-size: 1.5rem;
}

.swiper-container {
    height: 28rem;
    width: 100%;
}
  
 .buttons-list {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
 }