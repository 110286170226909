@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.footer {
    $self: &;
    font-family: 'Quicksand', sans-serif;

    &__banner {
        width: 100%;
        background-color: #22262d;
        padding: 2rem;
    }

    &__name {
        font-size: 1.1rem;
        line-height: 1.3rem;
        color: #fff;
    }

    &__date {
        font-size: 1.1rem;
        line-height: 1.3rem;
        color: #fff;
    }
}