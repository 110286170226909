@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.contentblock {
    $self: &;

    font-family: 'Quicksand', sans-serif;
    font-weight: 400;
    color: #fff;
    margin: 6rem 0;
    position: relative;

    &__title {
        text-align: center;
        font-size: 3.2rem;
        line-height: 3rem;
        font-weight: 700;
        margin: 1.5rem 0;
    }

    &__content {
        text-align: center;
        margin-bottom: 5rem;
        font-size: 1.2rem;
        line-height: 1.4rem;
    }

    &__DC-link {
        color: #ff2345;

        &:hover {
            color:#fff;
            text-decoration: none;
        }
    }

    &--intro {
        #{$self}__content{
            font-size: 1.4rem;
            line-height: 1.6rem;
        }

        &::after{
            content: '';
            position: absolute;
            height: 0.2rem;
            width: 40rem;
            background-color: #fff;
            left: calc(50% - 20rem);

            @media screen and (max-width: 768px) {
                width: 20rem;
                left: calc(50% - 10rem);
            }
        }
    }

    &--left,
    &--right {
        #{$self}__content{
            text-align: left;
            padding: 0 1rem;

            @media screen and (min-width: 768px) {
                padding: 0;
            }
        }
    }

    &--middle {
        #{$self}__content{
            text-align: center;
            padding: 0 1rem;
        }
    }

    &--alinea {
        margin: 1rem 0;
        font-weight: 500;

        #{$self}__content{
            text-align: left;
            margin-bottom: 1rem;
        }
    }
}