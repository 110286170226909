@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

.logo {
    $self: &;
    
    text-decoration: none;
    color: white;
    top: 0.75rem;
    left: 0.5rem;
    position: absolute;
    border-radius: 0.25rem;
    z-index: 5;

    &__image {
        width: 100%;
        height: 100%;
    }

    &__text {
        font-family: 'Quicksand', sans-serif;
        font-size: 2rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
    }
}