.icon {
    position: relative;

    &__download {
        &::before {
            content: '';
            position: absolute;
            height: 1rem;
            width: 1rem;
            background: url('../../assets/icons/download-icon.svg') no-repeat center center;
        }
    }
}